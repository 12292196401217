#formbox {
  background: #ffffff;
  position: relative;
  box-shadow: 0px 4px 21px 4px rgba(0, 72, 89, 0.15);
  // padding: 50px 30px;
  .nav-tabs {
    .nav-link {
      text-decoration: none;
    }
    .active {
      background: #00afd8;
      color: #fff;
    }
  }
  .topsection {
    text-align: center;
    padding: 50px 30px 30px 30px;
    cursor: pointer;
    h6 {
      font-size: 20px;
      font-weight: 500;
      color: #323232;
    }
    p {
      font-size: 15px;
      font-weight: 300;
      color: #6d6d6d;
      text-align: center;
    }
  }
  .rdrWeekDay {
    color: #000;
    font-weight: 500;
  }
  .contentbox {
    text-align: right;
    padding: 20px 50px;
    padding-top: 0;
    h6 {
      font-size: 15px;
      font-weight: 500;
      color: #323232;
    }
    .radioSelect {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 15px 0 25px;
      span {
        position: relative;
        display: inline-block;
        width: 52px;
        height: 53px;
        border-radius: 6px;
        overflow: hidden;
        input {
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 9;
          opacity: 0;
          cursor: pointer;
        }
        label {
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .actvRadio {
        label {
          background: #00afd8;
          color: #fff;
        }
      }
      button {
        border: 1px solid #00afd8;
        border-radius: 6px;
        background-color: #fff;
        color: #00afd8;
      }
    }
    .radioB {
      width: 100%;
      span {
        display: inline-block;
        padding-left: 30px;
        input[type="radio"] {
          margin-left: 10px;
          width: 16px;
          height: 16px;
          float: right;
          margin-top: 4px;
        }
      }
    }
    input[type="text"],
    input[type="date"],
    input[type="email"],
    select {
      background: #ffffff;
      border: 1px solid #d5e7ec;
      box-sizing: border-box;
      border-radius: 5px;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
      width: 100%;
      height: 53px;
      padding: 10px;
      margin-bottom: 15px;
      text-align: right;
      outline: none;
    }
    .contDetl {
      font-size: 12px;
      font-weight: 300;
      color: #909090;
    }
    button[type="button"] {
      background: #00afd8;
      border: 1px solid #00afd8;
      box-sizing: border-box;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      width: 100%;
    }
  }
  .back {
    text-decoration: none;
    padding: 10px 30px;
    // display: inline-block;
    float: right;
    &:hover {
      color: #323232;
    }
  }
}

.showmore span{
  margin-left: 10px;
}
.showmore span:nth-child(3){ margin-left: 0; }
.radio-trash h6{ flex: 1;}
.css-g1d714-ValueContainer{ height: 20px;}
.spNew{
  width: 33%;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
    padding-bottom: 5px;
    label{
      font-size: 15px;
      color: #6d6d6d;
    }
    input[type="checkbox"]{
      margin-left: 5px;
      width: 15px;
      height: 15px;
      position: relative;
      top: 2px;
    }
}

.contentwrap {
  background: url(../src/assets/images/Step_1.png) no-repeat // left center
    left top;
  // background-size: cover;
  background-size: 100%;
  padding: 60px 15px;
  position: relative;

  .logo {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 80px;
    height: 80px;
    background: url(../src/assets/images/logo.png) no-repeat center top;
    background-size: cover;
  }
  .container .col-md-6 {
    margin: 0px auto;
  }
  .container .col-md-6.custom-margin {
    margin-right: inherit;
  }
  #formbox .contentbox .radioSelect span {
    border: 1px solid #00afd8;
  }
  label {
    color: #666666;
  }
  #formbox .contentbox button[type="button"],
  button.btn.btn-primary,
  .formsubmit {
    background: #00afd8;
    border: 1px solid #00afd8;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    width: 100%;
    padding: 15px;
  }
  .contentbox {
    color: #666666;
    font-size: 14px;
    line-height: 20px;
  }
  .contentbox input[type="radio"] {
    margin: 5px 10px;
  }
  .contentbox p {
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    padding: 13px 0;
    margin-bottom: 0;
  }
  .contentbox p.hints {
    color: #000;
    margin: 70px 0px;
  }
  a {
    color: #80e5ff;
    text-decoration: underline;
  }
  .rdrCalendarWrapper {
    width: 100%;
    padding-bottom: 10px;
  }
  .rdrMonth {
    width: 100%;
  }

  .defbtn {
    background: #00afd8;
    border: 1px solid #00afd8;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    width: auto;
    padding: 10px;
    margin: 6px 10px;
  }
  #formbox .contentbox .radioSelect2 {
    justify-content: initial !important;
  }

  .contentwrap label {
    color: #666666;
    padding-left: 9px;
  }
  .card-body label {
    padding: 6px;
  }
}

.addingPassenger {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #f2f2f2;
  padding-top: 20px;

  .add-btn {
    width: 38px !important;
    padding: 4px !important;
    height: 38px;
    margin-left: 15px;
    background: #fff !important;
    color: #00afd8 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.setpBmain {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  .stepBinn {
    position: relative;
    width: 28%;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      margin: 0 !important;
      cursor: pointer;
    }
    .stepBimg {
      width: 100%;
      height: 100px;
      padding: 20px;
      border: 1px solid #d5e7ec;
      border-radius: 5px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}
.stepBinn:hover .stepBimg {
  cursor: pointer;
  background: #00afd8;
}
.stepBinn:hover .stepBimg img {
  filter: brightness(0) invert(1);
}
.stepBinnActive {
  cursor: pointer;
  background: #00afd8;
}
.stepBinnActive img {
  filter: brightness(0) invert(1);
}
.stepBinn:hover label {
  color: #00afd8;
}

.retdate {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
}
.retdate button {
  background: #ffffff;
  border: 1px solid #d5e7ec;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  width: 100%;
  height: 53px;
  padding: 10px;
  margin-bottom: 15px;
  text-align: right;
  margin: 0 20px;
}
.stepC p {
  text-align: right;
}
.incRadio {
  position: relative;
  cursor: pointer;
}
.incRadio input {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 30px;
  opacity: 0;
}
.incRadio {
  border: 1px solid #d5e7ec;
  padding: 10px;
  width: 45%;
}
.radioSelect.incRadioD {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.stepDmodal .modal-header button {
  background: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #868181;
  padding: 0;
  font-size: 22px;
}
.stepDmodal .modal-header button .sr-only {
  display: none;
}
.stepDmodal .modal-header {
  justify-content: flex-end;
  padding-bottom: 0;
  border: none;
}
.stepDmodal .modal-body {
  text-align: center;
}
.stepDmodal .modal-body h5 {
  font-size: 1.3rem;
}
.stepDmodal .modal-body h6 {
  font-size: 1.1rem;
  padding-top: 10px;
}
.stepDmodal .modal-body p {
  font-size: 0.9rem;
  line-height: 1.5rem;
  color: #6d6d6d;
  font-weight: 400;
}
.stepDmodal .modal-footer {
  justify-content: space-between;
}
.stepDmodal .modal-footer button {
  background: #00afd8;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #fff;
  padding: 13px 25px;
  width: 45%;
}
.comonCheck{
  float: right;
    margin: 0 0 0 10px;
}
.comonCheck input {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  display: inline-block;
  margin-right: 10px;
}

#formbox .contentbox .accordion .card-body {
  background: #e2ecee;
  a {
    color: #00aad6;
  }
}
.cartImg {
  margin-bottom: 20px;
}
.plusIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 19px;
  border: 1px solid #fff;
  border-radius: 50px;
  position: relative;
  top: 2px;
}
.undStd {
  background: none !important;
  color: #00aad6 !important;
}
.proposl h6,
.proposl p {
  text-align: right !important;
}

.stepfreturn {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-bottom: 30px;
}
.stepPrice {
  position: absolute;
  left: 0;
  top: 40px;
  background: #385e67;
  border-radius: 0px 5px 5px 0px;
  padding: 6px 8px;
}
.stepPrice p {
  margin: 0;
  font-size: 12px;
  color: #fff;
}
.stfiveboxborder .stf {
  background: #ffffff;
  border: 1px solid #d5e7ec;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 400;
  color: #666666;
  width: 100%;
  height: 53px;
  padding: 10px 15px !important;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px !important;
  box-shadow: 0 0 4px #b6e5f1;
}
.stfiveboxborder div{
  cursor: pointer;
}
.stfiveboxborder p {
  font-size: 15px;
  font-weight: 400;
  color: #323232 !important;
  width: auto;
  margin: 0;
  padding: 0 !important;
  display: inline-block;
}
.stfiveboxborder img {
  margin: 0 5px;
}
.stfPLus {
  font-size: 22px;
  color: #00aad6;
}

.stepDmodal .modal-body .date {
  font-size: 0.8rem;
}
.days label {
  padding-left: 12px;
  padding-right: 5px;
}
.underStnd {
  font-size: 15px;
  color: #00aad6;
  display: inline-block;
  margin: 10px 0;
}
.addCovr {
  background-color: #00afd8 !important;
  border: none !important;
  outline: none;
  border-radius: 5px;
  color: #fff;
  padding: 13px 25px !important;
  width: 100%;
}

.imgBordr {
  border: 1px solid #ccc;
  text-align: center;
  width: 80px;
  padding: 20px;
  margin: 0 auto 12px;
}
.sportCheck label {
  padding: 0 5px 0 13px;
}
.contentbox2 {
  padding: 50px 50px 20px 50px;
}

.rdrDateRangePickerWrapper {
  width: 100%;
  margin: 25px 0;
  box-shadow: 0px 0px 8px #b3ccd1;
}
.rdrDateRangePickerWrapper,
.rdrCalendarWrapper {
  border-radius: 20px;
}

// .rdrDateDisplay {
//   flex-direction: row-reverse;
// }

.return-release {
  display: flex;
  justify-content: space-around;
}

.thirdform {
  input::-webkit-calendar-picker-indicator {
    display: none;
  }
}

// .react-datepicker {
//   display: none;
// }
.rdrDateDisplayWrapper {
  background-color: rgb(239, 242, 247);
  display: none;
}
.incRadioActive {
  border: 2px solid #00afd8;
}

.radio-trash {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#formbox .contentbox .radio-trash .radioB {
  //width: auto;
}
.radio-trash svg {
  color: #d4c9c9;
  font-size: 17px;
}
.next-button-wr {
  padding: 30px 50px;
  box-shadow: 0px 0px 10px #ddd;
}
.contentbox {
  .css-2b097c-container {
    direction: rtl;
    .css-yk16xz-control,
    .css-1pahdxg-control {
      height: 54px;
      .css-1hwfws3{
        height: 100%;
      }
      .css-1wy0on6{
        top: 60%
      }
      
    }
  }
}

.return-input,
.release-input {
  .react-datepicker-wrapper {
    input {
      border: 1px solid #d5e7ec;
      border-radius: 5px;
      padding: 15px;
      font-size: 15px;
      color: #666666;
      &:focus {
        border-color: 1px solid#00afd8;
      }
    }
  }
}
input.form-control, input.form-control:focus {
  background: #F0F0FF;
}

.error, .errortext {
  color: red !important;
  font-size: .6rem !important;
  display: none;
  margin: unset !important;
  padding: unset !important;
}


// input:required:valid {
//   border-color: green !important;
// }
// input:invalid {
//   border-color: red !important;
// }
// input:required:focus:valid {
//   border-color: green !important;
// }
// input:focus:invalid {
//   border-color: red !important;
// }

.hcheckbox{
  position: relative;
  input{
    position: absolute;
    width: 100px;
    left: 50%;
    height: 100px;
    opacity: 0;
    transform: translate(-50%, -50%);
    top: 50%;
    cursor: pointer;
  }
  img{
    cursor: pointer;
  }
}
@media (max-width: 1399px){
  #formbox .contentbox .radioSelect span{ width: 40px; height: 40px;}
  }
  @media (max-width: 1199px){
    #formbox .contentbox .radioSelect span{ width: 30px;}
    }